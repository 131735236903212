<template>
  <div class="d-flex flex-row">
    <div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px" id="kt_profile_aside">
      <UserMenu :user=user></UserMenu>
    </div>
    <!--begin::Content-->
    <div class="flex-row-fluid ml-lg-8">
      <router-view :user=user />
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
import UserMenu from "@/view/pages/core/profile/ProfileMenu";
import ApiService from "@/core/services/api.service";

export default {
  components: {
    UserMenu
  },
  data() {
    return {
      user: null,
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    getUser() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/profil")
        .then((response) => {
          this.user = response.data.data;
          this.loading = false;
        });
    }
  }
};
</script>
